<template>
  <div>
    <main-dialog v-if="dialogs.mainDialog" />
    <topbar />
    <main-table />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import MainTable from '../components/releases/ReleasesMainTable.vue';
import Topbar from '../components/releases/ReleasesTopbar.vue';
import MainDialog from '../components/releases/ReleasesMainDialog.vue';

export default {
  name: 'Releases',

  components: {
    MainTable,
    Topbar,
    MainDialog,
  },

  computed: {
    ...mapGetters({
      dialogs: 'releases/dialogs',
    }),
  },

  created() {
    this.$store.dispatch('releases/loadReleases');
  },

  beforeDestroy() {
    this.$store.dispatch('releases/clearSelected');
  },
};
</script>
