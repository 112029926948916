<template>
  <v-dialog
    :value="dialogs.mainDialog"
    width="600"
    :overlay-opacity="0.9"
    scrollable
    @click:outside="closeDialog"
    @keydown.esc="closeDialog"
  >
    <v-card>
      <!-- ЗАГОЛОВОК -->
      <v-toolbar
        :height="48"
        dark
        color="primary"
        class="pr-0"
      >
        <v-toolbar-title>
          Добавить релиз
        </v-toolbar-title>

        <v-spacer />

        <v-toolbar-items>
          <v-btn
            icon
            @click="closeDialog"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-card-text class="pt-5">
        <v-row>
          <!-- ВЕРСИЯ -->
          <v-col :cols="12">
            <v-btn-toggle
              v-model="forMainDialog.release.version"
              mandatory
            >
              <v-btn
                v-for="version in ['stable', 'beta']"
                :key="`releases-main-dialog-version-${version}`"
                :value="version"
                x-small
                color="primary"
                outlined
              >
                {{ version }}
              </v-btn>
            </v-btn-toggle>
          </v-col>

          <!-- КОММЕНТАРИЙ -->
          <v-col :cols="12">
            <v-textarea
              v-model="forMainDialog.release.note"
              label="Комментарий"
              :rows="5"
              auto-grow
              dense
              outlined
              hide-details
              :spellcheck="false"
            />
          </v-col>
        </v-row>
      </v-card-text>

      <v-divider />

      <!-- КНОПКИ ДИАЛОГА -->
      <v-card-actions>
        <v-spacer />

        <v-btn
          small
          text
          color="grey"
          @click="closeDialog"
        >
          Закрыть
        </v-btn>

        <v-btn
          small
          text
          color="primary"
        >
          Добавить
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import { mapFields } from 'vuex-map-fields';

export default {
  name: 'ReleasesMainDialog',

  computed: {
    ...mapGetters({ dialogs: 'releases/dialogs' }),
    ...mapFields('releases', ['forMainDialog']),
  },

  beforeDestroy() {
    this.$store.dispatch('releases/clearForDialog');
  },

  methods: {
    closeDialog() {
      this.$store.dispatch('releases/closeDialog', 'mainDialog');
    },
  },

};
</script>
