<template>
  <div class="d-flex align-center justify-space-between mb-2">
    <!-- ЛЕВАЯ СТОРОНА -->
    <div />

    <!-- ПРАВАЯ СТОРОНА -->
    <div class="d-flex align-center">
      <!-- ДОБАВИТЬ РЕЛИЗ -->
      <v-tooltip
        v-if="profile.manageReleases"
        bottom
      >
        <template #activator="{ on }">
          <v-btn
            color="primary"
            icon
            class="mr-2"
            v-on="on"
            @click="addRelease"
          >
            <v-icon>
              mdi-plus
            </v-icon>
          </v-btn>
        </template>
        <span>Добавить релиз</span>
      </v-tooltip>

      <!-- ОБНОВИТЬ СПИСОК -->
      <v-tooltip bottom>
        <template #activator="{ on }">
          <v-btn
            class="ml-2 mr-4"
            color="primary"
            icon
            :disabled="loading.mainTable"
            v-on="on"
            @click="$store.dispatch('releases/loadCurrentPage')"
          >
            <v-icon>mdi-refresh</v-icon>
          </v-btn>
        </template>
        <span>Обновить</span>
      </v-tooltip>

      <!-- ПОИСК -->
      <v-text-field
        :value="topbarSearch.searchInput"
        label="Поиск"
        dense
        solo
        single-line
        hide-details
        clearable
        clear-icon="mdi-close"
        :spellcheck="false"
        @input="search"
      >
        <template #prepend-inner>
          <v-icon size="18">
            mdi-magnify
          </v-icon>
        </template>
      </v-text-field>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { mapFields } from 'vuex-map-fields';

export default {
  name: 'ReleasesTopbar',

  computed: {
    ...mapGetters({
      loading: 'releases/loading',
      profile: 'main/profile',
    }),
    ...mapFields('releases', ['topbarSearch']),
  },

  methods: {
    async addRelease() {
      await this.$store.dispatch('releases/setReleaseForMainDialog');
      this.$store.dispatch('releases/openDialog', 'mainDialog');
    },

    search(name) {
      this.topbarSearch.searchInput = name;
      setTimeout(async () => {
        if (name === this.topbarSearch.searchInput) {
          this.$store.dispatch('releases/loadReleases', { query: this.topbarSearch.searchInput });
        }
      }, 500);
    },
  },
};
</script>
