<template>
  <v-card>
    <!-- ТАБЛИЦА -->
    <v-data-table
      :items="releases.all"
      item-key="id"
      :headers="cols"
      fixed-header
      :height="innerHeight - 16 - 46 - 59"
      :items-per-page="footerOptions.itemsPerPage"
      hide-default-footer
      :loading="loading.mainTable"
    >
      <!-- ФУТЕР -->
      <template #footer>
        <v-data-footer
          :options="footerOptions"
          :pagination="footerPagination"
          :items-per-page-options="[10, 30, 50]"
          @update:options="updateFooterOptions"
        />
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex';
import { mapFields } from 'vuex-map-fields';

export default {
  name: 'ReleasesMainTable',

  computed: {
    ...mapGetters({
      loading: 'releases/loading',
      innerHeight: 'main/innerHeight',
      pagination: 'releases/pagination',
    }),
    ...mapFields('releases', ['releases']),

    cols() {
      const cols = [];

      cols.push({ text: 'Версия', value: 'version' });
      cols.push({ text: 'Автор', value: 'author' });
      cols.push({ text: 'Комментарий', value: 'note' });
      cols.push({ text: 'Статус', value: 'status' });

      return cols;
    },

    footerOptions() {
      const options = {};

      options.page = this.pagination.currentPage;
      options.itemsPerPage = this.pagination.perPage;

      return options;
    },

    footerPagination() {
      const pagination = {};

      pagination.page = this.pagination.currentPage;
      pagination.itemsPerPage = this.pagination.perPage;
      pagination.pageStart = this.pagination.from - 1;
      pagination.pageStop = this.pagination.to;
      pagination.pageCount = this.pagination.lastPage;
      pagination.itemsLength = this.pagination.total;

      return pagination;
    },
  },

  methods: {
    updateFooterOptions(options) {
      this.$store.dispatch('releases/loadReleases', { page: options.page, itemsPerPage: options.itemsPerPage });
    },
  },
};
</script>
